<template>
    <div id="artesanias">
        <v-app-bar flat color="transparent" class="px-1 mt-2" height="64" min-height="64">
        <v-breadcrumbs :items="items" class="pl-2 font-weight-medium body-1">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-text-field hide-details single-line prepend-inner-icon="mdi-magnify" placeholder="Escribe para buscar..." flat solo background-color="white" dense v-model="search"></v-text-field>
        <v-btn depressed color="primary darken-1" class="ml-3 text-none green--text text--lighten-5" to="/artesanias/nuevo">
            <span class="font-weight-bold">Nuevo</span>
            <v-icon color="green lighten-5" small right>mdi-plus</v-icon>
        </v-btn>
    </v-app-bar>
    <v-container fluid class="pt-0 px-5">
        <v-data-table :search="search" fixed-header @click:row="handleClick" :height="height" :headers="headers" :items="artesanias" :items-per-page="20" class="white" :header-props="{
                'sort-by-text': 'Ordenar por'
            }" no-results-text="No se encontraron resultados" no-data-text="No hay información para mostrar"
            :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text':'Filas por página',
            'pageText': '{0}-{1} de {2}',
            'items-per-page-all-text': 'Todas',
            'items-per-page-options': [
                20, 30, 45, -1
            ]
            }">
        </v-data-table>
        <transition name="slide-fade">
            <router-view name="artesanias" :height="height" :key="$route.path"/>
        </transition>
    </v-container>
    </div>
</template>
<script>
export default {
    components: {},
    name: 'Artesanias',
    data: () => ({
        search: null,
        height: 0,
        headers: [
            { text: 'Nombre de la artesanía', value: 'nombre' },
            { text: 'Técnica', value: 'tecnica' },
            { text: 'Origen artesanal', value: 'origenArtesanal' },
            { text: 'Origen botánico', value: 'origenBotanico' },
            { text: 'Origen animal', value: 'origenAnimal'},
            { text: 'Clave de colección', value: 'claveDeColeccion' },
        ],
        items: [
            {
                text: 'Inicio',
                disabled: false,
                href: '/',
            },
            {
                text: 'Artesanias',
                disabled: true,
                href: '',
            },
      ],
    }),
    methods: {
        handleClick(e) {
            this.$router.push({ name: 'edit-artesanias', params: { id: e._id } });
        },
        calculateHeight() {
            let paddings = 90;
            let appBar = 64;
            let divider = 1;
            let viewHeight = window.innerHeight;
            this.height = viewHeight - appBar - divider - paddings;
        },
    },
    computed: {
        artesanias() {
            return this.$store.getters['artesania/GET_ARTESANIAS'];
        }
    },
    created() {
        window.addEventListener("resize", this.calculateHeight);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.calculateHeight);
    },
    mounted() {
        this.calculateHeight();
    }
}
</script>